@font-face {
  font-family: 'LatoWeb';
  src: url('assets/fonts/Lato-Regular.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/Lato-Regular.woff2') format('woff2'), /* Modern Browsers */
       url('assets/fonts/Lato-Regular.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

body {
  margin: 0;
  font-family: 'LatoWeb', -apple-system, BlinkMacSystemFont, 'LatoWeb', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-layout {
  overflow-y: hidden;
  background: #F8F9FF;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.font-semi-bold {
  font-weight: 500;
}

.font-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic;
}

.ant-btn-primary {
  background-color: #2D53DA !important;
}

.ant-btn:active, .ant-btn:hover {
  border-color: #2D53DA !important;
  /* color: #2D53DA !important; */
}

/* canvas {
  width: 100% !important;
} */